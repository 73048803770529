<template>
  <div>
    <div class="w-full flex flex-row items-center justify-between">
      <div class="w-8/12">
        <span class="text-indigo-600 leading-tight mb-2 text-sm cursor-pointer" @click="goback()">Ga terug</span>

        <div class="mt-2 mb-2">
          <button @click="deletePlekje(object._id)" class="bg-red-500 hover:bg-red-700 text-white py-1 px-3 rounded">
            Verwijder
          </button>
          <button v-if="object.removed" @click="undeletePlekje(object)" class="ml-2 bg-green-500 hover:bg-red-700 text-white py-1 px-3 rounded">
            Zet plekje terug
          </button>
          <button @click="copyPlekje()" class="ml-2 bg-orange-500 hover:bg-orange-700 text-white py-1 px-3 rounded">
            Kopieer plekje
          </button>
          <button v-if="!object.hasMatch || false && !object.match.matchAllowed || false" @click="openPopup()" class="ml-2 bg-orange-500 hover:bg-orange-700 text-white py-1 px-3 rounded" style="background-color: rgb(32, 23, 71)">
            Maak match
          </button>
        </div>

        <h1 class="text-3xl font-semibold">
          {{ object.firstname }} {{ object.lastname }}
        </h1>
        <p v-if="object.id" class="text-gray-600 leading-tight mt-2 text-sm">
          <a :href="'https://circuitsortie.be/plekjes/' + object.id" target="_blank">https://circuitsortie.be/plekjes/{{ object.id }}</a>
        </p>
        <p class="text-gray-600 leading-tight mt-2 text-sm" v-else>
          <a :href="'https://circuitsortie.be/plekjes/' + object._id" target="_blank">https://circuitsortie.be/plekjes/{{ object._id }}</a>
        </p>
      </div>
      <div class="w-4/12">
        <button @click="save()" class="text-white py-2 px-4 rounded float-right ml-5 cursor-pointer" style="background-color: rgb(32, 23, 71)">
          Opslaan
        </button>

        <!-- Match:{{object.match}} -->


        <div v-if="!object.match">
          <a v-if="object.id" :href="settings.dashboardURL + '/plekjes/' + object.id" target="_blank" class="ml-5 text-white py-2 px-4 rounded float-right cursor-pointer" style="background-color: rgb(32, 23, 71)">Bekijk online</a>
          <a v-else :href="settings.dashboardURL + '/plekjes/' + object._id" target="_blank" class="ml-5 text-white py-2 px-4 rounded float-right cursor-pointer" style="background-color: rgb(32, 23, 71)">Bekijk online</a>
        </div>
        <div v-else>
          <router-link v-if="object.hasMatch" :to="'/matches/' + object.match._id" class="ml-5 text-white py-2 px-4 rounded float-right cursor-pointer" style="background-color: rgb(32, 23, 71)">Bekijk match</router-link>
          <!-- <router-link v-else :to="'/matches/' + object.match._id" class="ml-5 text-white py-2 px-4 rounded float-right cursor-pointer bg-red-600">Afgewezen match</router-link> -->
        </div>
      </div>
    </div>

    <div class="fixed z-50 inset-0 overflow-y-auto" v-if="popup">
      <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <div class="fixed inset-0 transition-opacity cursor-pointer" @click="closePopup()">
          <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>
        <span class="hidden sm:inline-block sm:align-middle sm:h-screen"></span>&#8203;
        <div class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle" role="dialog" aria-modal="true" aria-labelledby="modal-headline">
          <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
            <div class="sm:flex sm:items-start">
              <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                <h3 class="text-lg leading-6 font-medium text-gray-900" id="modal-headline">
                  Voeg plekje toe voor {{ object.id }}
                </h3>

                <div class="mt-5">
                  <div>
                    <p class="text-sm leading-5 font-medium mb-1 text-gray-500">
                      Image URL
                    </p>
                    <input type="text" class="border-2 border-gray-200 mb-4 rounded-lg px-3 py-2 text-sm" style="width: 100%" v-model="objectMatch.image" />
                  </div>
                  <div class="col-lg-4" id="grgQHJgpY">
                    <img ref="matchImage" width="150" height="150" id="output" alt="upload image" class="" onclick="document.getElementById('upload_image_input').click();" style="cursor: pointer" src="https://cdn.circuitsortie.be/fotos/150.png" /><br id="znbLToREc" />
                    <input type="file" accept="image/*" @change="uploadFiles('match')" id="upload_image_input" name="file" style="display: none" multiple />
                    <p v-if="uploaded">Bestand is geüpload</p>
                  </div>
                  <div class="flex flex-row">
                    <div class="w-6/12 pr-5">
                      <label class="block text-sm font-medium leading-5 text-gray-700 mt-5">Voornaam</label>
                      <div class="mt-2 flex rounded-md shadow-sm relative flex-row items-center">
                        <input class="form-input flex-1 block rounded-none rounded-r-md transition duration-150 ease-in-out text-sm sm:leading-5 border-gray-300" placeholder="Voornaam" v-model="objectMatch.firstname" />
                        <!--"-->
                      </div>
                    </div>
                    <div class="w-6/12">
                      <label class="block text-sm font-medium leading-5 text-gray-700 mt-5">Achternaam</label>
                      <div class="mt-2 flex rounded-md shadow-sm relative flex-row items-center">
                        <input class="form-input flex-1 block rounded-none rounded-r-md transition duration-150 ease-in-out text-sm sm:leading-5 border-gray-300" placeholder="Achternaam" v-model="objectMatch.lastname" />
                      </div>
                    </div>
                  </div>
                  <div class="flex flex-row">
                    <div class="w-6/12 pr-5">
                      <label class="block text-sm font-medium leading-5 text-gray-700 mt-5">E-mailadres</label>
                      <div class="mt-2 flex rounded-md shadow-sm relative flex-row items-center">
                        <input class="form-input flex-1 block rounded-none rounded-r-md transition duration-150 ease-in-out text-sm sm:leading-5 border-gray-300" placeholder="Email" v-model="objectMatch.email" />
                        <!--"-->
                      </div>
                    </div>
                    <div class="w-6/12">
                      <label class="block text-sm font-medium leading-5 text-gray-700 mt-5">Postcode & Woonplaats</label>
                      <div class="mt-2 flex rounded-md shadow-sm relative flex-row items-center">
                        <select class="form-input flex-1 block rounded-none rounded-r-md transition duration-150 ease-in-out text-sm sm:leading-5 border-gray-300" v-model="objectMatch.zip">
                          <option v-for="zip in zipCodes" :key="zip" :value="zip">{{zip}}</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div></div>
                  <div class="flex flex-row">
                    <div class="w-6/12 pr-5">
                      <label class="block text-sm font-medium leading-5 text-gray-700 mt-5">Geboortejaar</label>
                      <select class="border-2 border-gray-200 mb-4 rounded-lg px-3 py-2 text-sm" style="width: 100%" v-model="objectMatch.birthYear">
                        <option v-for="i in 100" v-bind:key="i">
                          {{ 2016 - i }}
                        </option>
                      </select>
                    </div>
                    <div class="w-6/12">
                      <label class="block text-sm font-medium leading-5 text-gray-700 mt-5">Telefoon</label>
                      <div class="mt-2 flex rounded-md shadow-sm relative flex-row items-center">
                        <input class="form-input flex-1 block rounded-none rounded-r-md transition duration-150 ease-in-out text-sm sm:leading-5 border-gray-300" placeholder="Telefoonnummer" v-model="objectMatch.phone" />
                      </div>
                    </div>
                  </div>
                  <div class="form-group text-gray-700">
                    <p class="text-sm leading-5 font-medium mb-1 text-gray-500">
                      Geslacht
                    </p>
                    <input id="gender_male" type="radio" name="gender" value="male" checked="" v-model="objectMatch.gender" />
                    <label for="gender_male" class="pointer"> Man</label><br />
                    <input id="gender_female" type="radio" name="gender" value="female" v-model="objectMatch.gender" />
                    <label for="gender_female" class="pointer"> Vrouw</label><br />
                    <input id="gender_other" type="radio" name="gender" value="other" v-model="objectMatch.gender" />
                    <label for="gender_other" class="pointer"> Andere</label><br />
                    <input id="gender_noshare" type="radio" name="gender" value="noshare" v-model="objectMatch.gender" />
                    <label for="gender_noshare" class="pointer"> Deel ik liever niet</label><br />
                  </div>
                  <div class="form-group text-gray-700">
                    <p class="text-sm leading-5 font-medium mb-1 text-gray-500">
                      Beperking
                    </p>
                    <input id="beperking_ja" type="radio" name="beperking" value="true" v-model="objectMatch.beperking" />
                    <label for="beperking_ja" class="pointer" required="">
                      Ja</label><br />
                    <input id="beperking_nee" type="radio" name="beperking" value="false" v-model="objectMatch.beperking" checked="" />
                    <label for="beperking_nee" class="pointer" required="">
                      Nee</label><br />
                  </div>
                  <div id="beperking_questions" v-if="
                      objectMatch.beperking &&
                      objectMatch.beperking.toString() === 'true'
                    ">
                    <p class="text-sm leading-5 font-medium mb-1 text-gray-500">
                      Soort beperking
                    </p>
                    <div class="mt-2 flex rounded-md shadow-sm relative flex-row items-center">
                      <select class="border-2 border-gray-200 mb-4 rounded-lg px-3 py-2 text-sm" style="width: 100%" v-model="objectMatch.beperking_type">
                        <option value="Fysieke beperking">
                          Fysieke beperking
                        </option>
                        <option value="Verstandelijke beperking">
                          Verstandelijke beperking
                        </option>
                        <option value="Autismespectrumstoornis">
                          Autismespectrumstoornis
                        </option>
                        <option value="Visuele beperking">
                          Visuele beperking
                        </option>
                        <option value="">Auditieve beperking</option>
                      </select>
                    </div>
                    <br />
                    <p class="text-sm leading-5 font-medium mb-1 text-gray-500">
                      Verloopt de communicatie via een contactpersoon? Plaats
                      dan hieronder de contactegegevens:
                    </p>
                    <div class="flex flex-row">
                      <div class="w-6/12 pr-5">
                        <label class="block text-sm font-medium leading-5 text-gray-700 mt-5">Voornaam begeleider</label>
                        <div class="mt-2 flex rounded-md shadow-sm relative flex-row items-center">
                          <input class="form-input flex-1 block rounded-none rounded-r-md transition duration-150 ease-in-out text-sm sm:leading-5 border-gray-300" placeholder="Voornaam" v-model="objectMatch.firstname_begeleider" />
                          <!--"-->
                        </div>
                      </div>
                      <div class="w-6/12">
                        <label class="block text-sm font-medium leading-5 text-gray-700 mt-5">Familienaam begeleider</label>
                        <div class="mt-2 flex rounded-md shadow-sm relative flex-row items-center">
                          <input class="form-input flex-1 block rounded-none rounded-r-md transition duration-150 ease-in-out text-sm sm:leading-5 border-gray-300" placeholder="Familienaam" v-model="objectMatch.lastname_begeleider" />
                        </div>
                      </div>
                    </div>
                    <div class="flex flex-row">
                      <div class="w-6/12 pr-5">
                        <label class="block text-sm font-medium leading-5 text-gray-700 mt-5">E-mailadres begeleider</label>
                        <div class="mt-2 flex rounded-md shadow-sm relative flex-row items-center">
                          <input class="form-input flex-1 block rounded-none rounded-r-md transition duration-150 ease-in-out text-sm sm:leading-5 border-gray-300" placeholder="Email" v-model="objectMatch.email_begeleider" />
                          <!--"-->
                        </div>
                      </div>
                      <div class="w-6/12">
                        <label class="block text-sm font-medium leading-5 text-gray-700 mt-5">Telefoon begeleider</label>
                        <div class="mt-2 flex rounded-md shadow-sm relative flex-row items-center">
                          <input class="form-input flex-1 block rounded-none rounded-r-md transition duration-150 ease-in-out text-sm sm:leading-5 border-gray-300" placeholder="Telefoonnummer" v-model="objectMatch.phone_begeleider" />
                        </div>
                      </div>
                    </div>
                    <div class="form-group" id="wxFeJAJyF">
                      <label class="block text-sm font-medium leading-5 text-gray-700 mt-5">
                        Waar heb je (extra) ondersteuning bij nodig?
                      </label>
                      <div class="flex flex-col mt-2">
                        <select v-model="objectMatch.hulp" class="border-2 border-gray-200 rounded-lg px-3 py-2 text-sm">
                          <option :value="undefined">Selecteer optie</option>
                          <option value="Rolstoel duwen">Rolstoel duwen</option>
                          <option value="Bij het eten">Bij het eten</option>
                          <option value="Bij het drinken">Bij het drinken</option>
                          <option value="Naar het toilet gaan">Naar het toilet gaan</option>
                          <option value="Ik ben blind/slechtziend">Ik ben blind/slecthziend</option>
                          <option value="Ik ben doof/slechthorend">Ik ben doof/slechthorend</option>
                          <option value="Ik heb speciaal vervoer nodig">Ik heb speciaal vervoer nodig</option>
                          <option value="Andere">Andere</option>
                        </select>

                        <label for="hulpNotes" class="block text-sm font-medium leading-5 text-gray-700 mt-5">Vertel ons hieronder (indien nodig) wat meer over de ondersteuning die je nodig hebt.</label>
                        <textarea name="hulpNotes" class='form-input my-2 flex-1 block rounded-none rounded-r-md transition duration-150 ease-in-out text-sm sm:leading-5 border-gray-300' placeholder='hulp notities' v-model='objectMatch.hulpNotes' />
                      </div>
                    </div>
                  </div>
                  <div class=" form-group text-gray-700">
                    <p class="text-sm leading-5 font-medium mb-1 text-gray-500">
                      Lid:
                    </p>
                    <input id="lid_neos" type="radio" name="lid" v-model="objectMatch.lid" value="neos" />
                    <label for="lid_neos" class="pointer"> Neos</label><br />
                    <input id="lied_cera" type="radio" name="lid" v-model="objectMatch.lid" value="cera" />
                    <label for="lied_cera" class="pointer">Cera-vennoot</label><br />
                    <input id="no-member" type="radio" name="lid" v-model="objectMatch.lid" value="geen" checked />
                    <label for="no-member" class="pointer">Geen</label><br />
                  </div>
                  <div>
                    <p class="text-sm leading-5 font-medium mb-1 text-gray-500">
                      Extra
                    </p>
                    <input type="text" class="border-2 border-gray-200 mb-4 rounded-lg px-3 py-2 text-sm" style="width: 100%" v-model="objectMatch.other" />
                  </div>
                  <div>
                    <label>
                      Ja, hou me via e-mail op de hoogte van nieuwe, toffe
                      acties van de Genereuzen.<br />
                      <input type="checkbox" name="newsGen" value="Acties Genereuzen" v-model="objectMatch.newsGen" />
                      Zeker aanvinken. Onze database is ons enige instrument om
                      de boodschap van De Genereuzen te verspreiden. <br />
                      Zo maken we samen de beweging groter. Dank je wel!</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
            <span class="flex w-full rounded-md shadow-sm sm:ml-3 sm:w-auto">
              <button type="button" @click="createMatch()" class="inline-flex justify-center w-full rounded-md border border-transparent px-4 py-2 bg-green-600 text-base leading-6 font-medium text-white shadow-sm hover:bg-green-500 focus:outline-none focus:border-green-700 focus:shadow-outline-green transition ease-in-out duration-150 sm:text-sm sm:leading-5">
                Match opslaan
              </button>
            </span>

            <span class="mt-3 flex w-full rounded-md shadow-sm sm:mt-0 sm:w-auto">
              <button @click="popup = false" type="button" class="inline-flex justify-center w-full rounded-md border border-gray-300 px-4 py-2 bg-white text-base leading-6 font-medium text-gray-700 shadow-sm hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue transition ease-in-out duration-150 sm:text-sm sm:leading-5">
                Cancel
              </button>
            </span>
          </div>
        </div>
      </div>
    </div>

    <div class="w-full items-start justify-start flex flex-row">
      <div class="w-full flex flex-col border-b border-gray-300 pb-10">
        <div class="w-full mt-5 flex flex-row">
          <!--FORM-->
          <div class="w-6/12 pt-5 pr-10">
            <h3 class="text-2xl font-semibold leading-5 flex flex-row items-center">
              1. Basis informatie
            </h3>
            <div class="flex flex-row">
              <div class="w-6/12 pr-5">
                <label class="block text-sm font-medium leading-5 text-gray-700 mt-5">Voornaam</label>
                <div class="mt-2 flex rounded-md shadow-sm relative flex-row items-center">
                  <input class="form-input flex-1 block rounded-none rounded-r-md transition duration-150 ease-in-out text-sm sm:leading-5 border-gray-300" placeholder="Voornaam" v-model="object.firstname" @change="change()" />
                  <!--"-->
                </div>
              </div>
              <div class="w-6/12">
                <label class="block text-sm font-medium leading-5 text-gray-700 mt-5">Achternaam</label>
                <div class="mt-2 flex rounded-md shadow-sm relative flex-row items-center">
                  <input class="form-input flex-1 block rounded-none rounded-r-md transition duration-150 ease-in-out text-sm sm:leading-5 border-gray-300" placeholder="Achternaam" v-model="object.lastname" @change="change()" />
                </div>
              </div>
            </div>

            <div class="flex flex-row">
              <div class="w-6/12 pr-5">
                <label class="block text-sm font-medium leading-5 text-gray-700 mt-5">Email</label>
                <div class="mt-2 flex rounded-md shadow-sm relative flex-row items-center">
                  <input class="form-input flex-1 block rounded-none rounded-r-md transition duration-150 ease-in-out text-sm sm:leading-5 border-gray-300" placeholder="Email" v-model="object.owner" @change="change()" />
                </div>
              </div>
              <div class="w-6/12">
                <label class="block text-sm font-medium leading-5 text-gray-700 mt-5">Telefoon</label>
                <div class="mt-2 flex rounded-md shadow-sm relative flex-row items-center">
                  <input class="form-input flex-1 block rounded-none rounded-r-md transition duration-150 ease-in-out text-sm sm:leading-5 border-gray-300" placeholder="Telefoon" v-model="object.phone" @change="change()" />
                </div>
              </div>
            </div>

            <div class="flex flex-row">
              <div class="w-6/12 pr-5" v-if="object.birthYear">
                <label class="block text-sm font-medium leading-5 text-gray-700 mt-5">Geboortejaar</label>
                <div class="mt-2 flex rounded-md shadow-sm relative flex-row items-center">
                  <input class="form-input flex-1 block rounded-none rounded-r-md transition duration-150 ease-in-out text-sm sm:leading-5 border-gray-300" placeholder="Geboortejaar" v-model="object.birthYear" @change="change()" />
                </div>
              </div>
              <div class="w-6/12">
                <label class="block text-sm font-medium leading-5 text-gray-700 mt-5">Zip</label>
                <div class="mt-2 flex rounded-md shadow-sm relative flex-row items-center">
                  <input class="form-input flex-1 block rounded-none rounded-r-md transition duration-150 ease-in-out text-sm sm:leading-5 border-gray-300" placeholder="Zip" v-model="object.zip" @change="change()" />
                </div>
              </div>
            </div>

            <div class="flex flex-row">
              <div class="w-6/12 pr-5">
                <label class="block text-sm font-medium leading-5 text-gray-700 mt-5">Geslacht</label>
                <div class="mt-2 flex rounded-md shadow-sm relative flex-row items-center">
                  <select v-model="object.gender" class="form-input flex-1 block w-full rounded-none rounded-r-md transition duration-150 ease-in-out text-sm sm:leading-5 border-gray-300" placeholder="Geslacht" @change="change()">
                    <option value="male">Man</option>
                    <option value="female">Vrouw</option>
                    <option value="">Anders</option>
                  </select>
                  <svg class="w-6 h-6 absolute right-0 mr-3" version="1.1" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                    <g fill="none">
                      <path d="M0,0h24v24h-24Z"></path>
                      <path stroke="#323232" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M8,10l4,4l4,-4"></path>
                    </g>
                  </svg>
                </div>
              </div>
              <div class="w-6/12">
                <label class="block text-sm font-medium leading-5 text-gray-700 mt-5">Beperking</label>
                <div class="mt-2 flex rounded-md shadow-sm relative flex-row items-center">
                  <select v-model="object.beperking" class="form-input flex-1 block w-full rounded-none rounded-r-md transition duration-150 ease-in-out text-sm sm:leading-5 border-gray-300" placeholder="Beperking" @change="change()">
                    <option value="true">Ja</option>
                    <option value="false">Nee</option>
                  </select>
                  <svg class="w-6 h-6 absolute right-0 mr-3" version="1.1" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                    <g fill="none">
                      <path d="M0,0h24v24h-24Z"></path>
                      <path stroke="#323232" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M8,10l4,4l4,-4"></path>
                    </g>
                  </svg>
                </div>
              </div>
            </div>

            <div class="flex flex-row">
              <div class="w-6/12 pr-5">
                <label class="block text-sm font-medium leading-5 text-gray-700 mt-5">NewsGen</label>
                <div class="mt-2 flex rounded-md shadow-sm relative flex-row items-center">
                  <select v-model="object.newsGen" class="form-input flex-1 block w-full rounded-none rounded-r-md transition duration-150 ease-in-out text-sm sm:leading-5 border-gray-300" placeholder="newsGen" @change="change()">
                    <option value="true">Ja</option>
                    <option value="false">Nee</option>
                  </select>
                  <svg class="w-6 h-6 absolute right-0 mr-3" version="1.1" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                    <g fill="none">
                      <path d="M0,0h24v24h-24Z"></path>
                      <path stroke="#323232" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M8,10l4,4l4,-4"></path>
                    </g>
                  </svg>
                </div>
              </div>
              <div class="w-6/12">
                <label class="block text-sm font-medium leading-5 text-gray-700 mt-5">Image URL</label>
                <div class="mt-2 flex rounded-md shadow-sm relative flex-row items-center">
                  <input class="form-input flex-1 block rounded-none rounded-r-md transition duration-150 ease-in-out text-sm sm:leading-5 border-gray-300" placeholder="Image URL" v-model="object.image" @change="change()" />
                </div>
              </div>
            </div>

            <div class="flex flex-row">
              <div class="w-6/12 pr-5">
                <label class="block text-sm font-medium leading-5 text-gray-700 mt-5">Lid</label>
                <div class="mt-2 flex rounded-md shadow-sm relative flex-row items-center">
                  <select v-model="object.lid" class="form-input flex-1 block w-full rounded-none rounded-r-md transition duration-150 ease-in-out text-sm sm:leading-5 border-gray-300" @change="change()">
                    <option value="neos">Neos</option>
                    <option value="cera">Cera</option>
                    <option value="geen">Geen</option>
                  </select>
                  <svg class="w-6 h-6 absolute right-0 mr-3" version="1.1" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                    <g fill="none">
                      <path d="M0,0h24v24h-24Z"></path>
                      <path stroke="#323232" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M8,10l4,4l4,-4"></path>
                    </g>
                  </svg>
                </div>
              </div>

              <div class="w-6/12">
                <label class="block text-sm font-medium leading-5 text-gray-700 mt-5">Ranking van Plekje (1 is eerste, 99 is laatst)</label>
                <div class="mt-2 flex rounded-md shadow-sm relative flex-row items-center">
                  <input class="form-input flex-1 block rounded-none rounded-r-md transition duration-150 ease-in-out text-sm sm:leading-5 border-gray-300" placeholder="99" v-model="object.order" />
                </div>
              </div>

            </div>

            <label class="block text-sm font-medium leading-5 text-gray-700 mt-5"><b id="TchhVrmOt" required="">Voeg hier een foto van de persoon toe.
              </b>
            </label>
            <div class="col-lg-4" id="grgQHJgpY">
              <img width="150" height="150" id="output" onclick="document.getElementById('upload_image_input').click();" style="cursor: pointer" src="https://cdn.circuitsortie.be/fotos/150.png" /><br id="znbLToREc" />
              <input type="file" accept="image/*" @change="uploadFiles('plekje')" id="upload_image_input" name="file" style="display: none" multiple />
              <p v-if="uploaded">Bestand is geüpload</p>
            </div>


            <h3 class="text-2xl font-semibold leading-5 flex flex-row items-center mt-10">
              2. Begeleider
            </h3>

            <div class="flex flex-row">
              <div class="w-6/12 pr-5">
                <label class="block text-sm font-medium leading-5 text-gray-700 mt-5">Voornaam begeleider</label>
                <div class="mt-2 flex rounded-md shadow-sm relative flex-row items-center">
                  <input class="form-input flex-1 block rounded-none rounded-r-md transition duration-150 ease-in-out text-sm sm:leading-5 border-gray-300" placeholder="Voornaam begeleider" v-model="object.firstname_begeleider" @change="change()" />
                </div>
              </div>
              <div class="w-6/12">
                <label class="block text-sm font-medium leading-5 text-gray-700 mt-5">Achternaam begeleider</label>
                <div class="mt-2 flex rounded-md shadow-sm relative flex-row items-center">
                  <input class="form-input flex-1 block rounded-none rounded-r-md transition duration-150 ease-in-out text-sm sm:leading-5 border-gray-300" placeholder="Achternaam begeleider" v-model="object.lastname_begeleider" @change="change()" />
                </div>
              </div>
            </div>

            <div class="flex flex-row">
              <div class="w-6/12 pr-5">
                <label class="block text-sm font-medium leading-5 text-gray-700 mt-5">Email begeleider</label>
                <div class="mt-2 flex rounded-md shadow-sm relative flex-row items-center">
                  <input class="form-input flex-1 block rounded-none rounded-r-md transition duration-150 ease-in-out text-sm sm:leading-5 border-gray-300" placeholder="Email begeleider" v-model="object.email_begeleider" @change="change()" />
                </div>
              </div>
              <div class="w-6/12">
                <label class="block text-sm font-medium leading-5 text-gray-700 mt-5">Telefoon begeleider</label>
                <div class="mt-2 flex rounded-md shadow-sm relative flex-row items-center">
                  <input class="form-input flex-1 block rounded-none rounded-r-md transition duration-150 ease-in-out text-sm sm:leading-5 border-gray-300" placeholder="Telefoon begeleider" v-model="object.phone_begeleider" @change="change()" />
                </div>
              </div>
            </div>

            <h3 class="text-2xl font-semibold leading-5 flex flex-row items-center mt-10">
              3. Beperking
            </h3>
            <label class="block text-sm font-medium leading-5 text-gray-700 mt-5">Soort beperking</label>
            <div class="mt-2 flex rounded-md shadow-sm relative flex-row items-center">
              <textarea rows="1" class="form-input flex-1 block rounded-none rounded-r-md transition duration-150 ease-in-out text-sm sm:leading-5 border-gray-300" placeholder="Soort beperking" v-model="object.beperking_type" @change="change()"></textarea>
            </div>
            <label class="block text-sm font-medium leading-5 text-gray-700 mt-5">Soort hulp</label>
            <div class="mt-2 flex rounded-md shadow-sm relative flex-row items-center">
              <textarea rows="3" class="form-input flex-1 block rounded-none rounded-r-md transition duration-150 ease-in-out text-sm sm:leading-5 border-gray-300" placeholder="Soort hulp" v-model="object.hulp" @change="change()"></textarea>
            </div>

            <label class="block text-sm font-medium leading-5 text-gray-700 mt-5">Hulp notes</label>
            <div class="mt-2 flex rounded-md shadow-sm relative flex-row items-center">
              <textarea rows="3" class="form-input flex-1 block rounded-none rounded-r-md transition duration-150 ease-in-out text-sm sm:leading-5 border-gray-300" placeholder="Hulp notities" v-model="object.hulpNotes" @change="change()"></textarea>
            </div>
            <h3 class="text-2xl font-semibold leading-5 flex flex-row items-center mt-10">
              4. Interne Opmerkingen
            </h3>
            <label class="block text-sm font-medium leading-5 text-gray-700 mt-5">Interne opmerking</label>
            <div class="mt-2 flex rounded-md shadow-sm relative flex-row items-center">
              <textarea rows="3" class="form-input flex-1 block rounded-none rounded-r-md transition duration-150 ease-in-out text-sm sm:leading-5 border-gray-300" placeholder="Opmerkingen" v-model="object.internalremarks" @change="change()"></textarea>
            </div>
          </div>
          <div class="w-6/12 pt-5 pr-10">
            <h3 class="text-2xl font-semibold leading-5 flex flex-row items-center">
              5. Bijhorende afbeelding
            </h3>

            <img v-if="object.image" class="p-5" :src="object.image" />
            <img v-else class="p-5" src="https://cdn.circuitsortie.be/fotos/logo-circuit-sortie.png" />
            <h3 class="mt-10 text-2xl font-semibold leading-5 flex flex-row items-center">
              6. Notities
            </h3>

            <label class="block text-sm font-medium leading-5 text-gray-700 mt-5">Opmerkingen</label>
            <div class="mt-2 flex rounded-md shadow-sm relative flex-row items-center">
              <textarea rows="3" class="form-input flex-1 block rounded-none rounded-r-md transition duration-150 ease-in-out text-sm sm:leading-5 border-gray-300" placeholder="Extra opmerkingen" v-model="object.other" @change="change()"></textarea>
            </div>

            <h3 class="text-2xl font-semibold leading-5 flex flex-row items-center mt-10">
              7. Plekje informatie
            </h3>

            <label class="block text-sm font-medium leading-5 text-gray-700 mt-5">{{ object.firstname }} wil graag
            </label>
            <div class="mt-2 flex rounded-md shadow-sm relative flex-row items-center">
              <input class="form-input flex-1 block rounded-none rounded-r-md transition duration-150 ease-in-out text-sm sm:leading-5 border-gray-300" placeholder="What" v-model="object.what" @change="change()" />
            </div>

            <label class="block text-sm font-medium leading-5 text-gray-700 mt-5">Waar</label>
            <div v-if="object.type == 'festival' && festival">
              {{festival.name}} op {{object.festivalDate}}
            </div>
            <div v-else>
            <div class="mt-2 flex rounded-md shadow-sm relative flex-row items-center">
              <input class="form-input flex-1 block rounded-none rounded-r-md transition duration-150 ease-in-out text-sm sm:leading-5 border-gray-300" placeholder="Where" v-model="object.where" @change="change()" />
            </div>

          </div>


            <label class="block text-sm font-medium leading-5 text-gray-700 mt-5">Omdat</label>
            <div class="mt-2 flex rounded-md shadow-sm relative flex-row items-center">
              <input class="form-input flex-1 block rounded-none rounded-r-md transition duration-150 ease-in-out text-sm sm:leading-5 border-gray-300" placeholder="Why" v-model="object.why" @change="change()" />
            </div>

            <div class="flex flex-row">
              <div class="w-6/12 pr-5">
                <label class="block text-sm font-medium leading-5 text-gray-700 mt-5">Latitude</label>
                <div class="mt-2 flex rounded-md shadow-sm relative flex-row items-center">
                  <input class="form-input flex-1 block rounded-none rounded-r-md transition duration-150 ease-in-out text-sm sm:leading-5 border-gray-300" placeholder="Latitude" v-model="object.latitude" @change="change()" />
                </div>
              </div>
              <div class="w-6/12">
                <label class="block text-sm font-medium leading-5 text-gray-700 mt-5">Longitude</label>
                <div class="mt-2 flex rounded-md shadow-sm relative flex-row items-center">
                  <input class="form-input flex-1 block rounded-none rounded-r-md transition duration-150 ease-in-out text-sm sm:leading-5 border-gray-300" placeholder="Longitude" v-model="object.longitude" @change="change()" />
                </div>
              </div>
            </div>
            <a style="color: blue; text-decoration: underline" :href="
                'https://www.latlong.net/c/?lat=' +
                object.latitude +
                '&long=' +
                object.longitude
              " target="_blank">Bekijk op map</a>
            <h3 class="text-2xl font-semibold leading-5 flex flex-row items-center mt-10">
              8. Technische informatie
            </h3>

            <div class="flex flex-row">
              <div class="w-6/12 pr-5">
                <label class="block text-sm font-medium leading-5 text-gray-700 mt-5">Plekje goedgekeurd</label>
                <div class="mt-2 flex rounded-md shadow-sm relative flex-row items-center">
                  <select v-model="object.active" class="form-input flex-1 block w-full rounded-none rounded-r-md transition duration-150 ease-in-out text-sm sm:leading-5 border-gray-300" placeholder="active" @change="change()">
                    <option value="true">Ja</option>
                    <option value="false">Nee</option>
                  </select>
                  <svg class="w-6 h-6 absolute right-0 mr-3" version="1.1" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                    <g fill="none">
                      <path d="M0,0h24v24h-24Z"></path>
                      <path stroke="#323232" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M8,10l4,4l4,-4"></path>
                    </g>
                  </svg>
                </div>
              </div>
              <div class="w-6/12" v-if="false">
                <label class="block text-sm font-medium leading-5 text-gray-700 mt-5">PlekjeId</label>
                <div class="mt-2 flex rounded-md shadow-sm relative flex-row items-center">
                  <input class="form-input flex-1 block rounded-none rounded-r-md transition duration-150 ease-in-out text-sm sm:leading-5 border-gray-300" placeholder="Plekje id" v-model="object.id" @change="change()" />
                </div>
                <small>Moet uniek zijn. Mag geen spaties (vervang door -) of
                  karakters als ?/# bevatten
                </small>
                <br />
                <small>Url:
                  <a :href="settings.dashboardURL + '/plekjes/' + object.id">https://{{settings.dashboardURL}}/plekjes/{{ object.id }}</a>
                </small>
              </div>
            </div>

            <div class="flex flex-row">
              <div class="w-6/12 pr-5">
                <label class="block text-sm font-medium leading-5 text-gray-700 mt-5">Plekje heeft een antwoord</label>
                <div class="mt-2 flex rounded-md shadow-sm relative flex-row items-center">
                  <select v-model="object.hasReply" class="form-input flex-1 block w-full rounded-none rounded-r-md transition duration-150 ease-in-out text-sm sm:leading-5 border-gray-300" placeholder="hasReply" @change="change()">
                    <option value="true">
                      Ja (Geen nieuwe reacties mogelijk)
                    </option>
                    <option value="false">
                      Nee (nieuwe reacties mogelijk)
                    </option>
                  </select>
                  <svg class="w-6 h-6 absolute right-0 mr-3" version="1.1" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                    <g fill="none">
                      <path d="M0,0h24v24h-24Z"></path>
                      <path stroke="#323232" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M8,10l4,4l4,-4"></path>
                    </g>
                  </svg>
                </div>
              </div>

            </div>
            <div class="flex flex-row">
              <div class="w-6/12 pr-5">
                <label class="block text-sm font-medium leading-5 text-gray-700 mt-5">Verwijderd</label>
                <input type="checkbox" class="form-checkbox" v-model="object.removed" @change="change()" />
              </div>
              <div class="w-6/12 pr-5">
                <label class="block text-sm font-medium leading-5 text-gray-700 mt-5">Actief</label>
                <input type="checkbox" class="form-checkbox" v-model="object.active" @change="change()" />
              </div>
            </div>
            <div class="flex flex-row">
              <div class="w-6/12 pr-5">
                <label class="block text-sm font-medium leading-5 text-gray-700 mt-5">Automatische mail active verzonden</label>
                <input type="checkbox" class="form-checkbox" v-model="object.automaticMailActiveSent" @change="change()" />
              </div>
              <div class="w-6/12 pr-5">
                <label class="block text-sm font-medium leading-5 text-gray-700 mt-5">Heeft een match</label>
                <input type="checkbox" class="form-checkbox" v-model="object.hasMatch" @change="change()" />
              </div>
            </div>
            <h3 class="text-2xl font-semibold leading-5 flex flex-row items-center mt-10">
              9. Bronnen
            </h3>
            <div class="flex flex-row">

              <div class="w-6/12 pr-5">
                <label class="block text-sm font-medium leading-5 text-gray-700 mt-5">Type plekje</label>
                <div class="mt-2 flex rounded-md shadow-sm relative flex-row items-center">
                  <select v-model="object.type" class="form-input flex-1 block w-full rounded-none rounded-r-md transition duration-150 ease-in-out text-sm sm:leading-5 border-gray-300" placeholder="active" @change="change()">
                    <option value="custom">Custom (standaard)</option>
                    <option value="festival">Festival</option>
                  </select>
                  <svg class="w-6 h-6 absolute right-0 mr-3" version="1.1" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                    <g fill="none">
                      <path d="M0,0h24v24h-24Z"></path>
                      <path stroke="#323232" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M8,10l4,4l4,-4"></path>
                    </g>
                  </svg>
                </div>
              </div>


              <div v-if="object.type == 'festival'" class="w-6/12 pr-5">
                <label class="block text-sm font-medium leading-5 text-gray-700 mt-5">Festival</label>
                <div class="mt-2 flex rounded-md shadow-sm relative flex-row items-center">
                  <select v-model="object.festival" class="form-input flex-1 block w-full rounded-none rounded-r-md transition duration-150 ease-in-out text-sm sm:leading-5 border-gray-300" placeholder="active" @change="change()">
                    <option v-for="festival in festivals" v-bind:key="festival._id" :value="festival._id">{{festival.name}}</option>
                  </select>
                  <svg class="w-6 h-6 absolute right-0 mr-3" version="1.1" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                    <g fill="none">
                      <path d="M0,0h24v24h-24Z"></path>
                      <path stroke="#323232" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M8,10l4,4l4,-4"></path>
                    </g>
                  </svg>
                </div>
              </div>

              <div v-if="object.type == 'festival' && festival" class="w-6/12 pr-5">
                <label class="block text-sm font-medium leading-5 text-gray-700 mt-5">Datum for {{festival.name}}</label>
                <div class="mt-2 flex rounded-md shadow-sm relative flex-row items-center">
                  <select v-model="object.festivalDate" class="form-input flex-1 block w-full rounded-none rounded-r-md transition duration-150 ease-in-out text-sm sm:leading-5 border-gray-300" placeholder="active" @change="change()">
                    <option v-for="date in festival.dates"  v-bind:key="date.label" :value="date.label">{{date.label}}</option>
                  </select>
                  <svg class="w-6 h-6 absolute right-0 mr-3" version="1.1" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                    <g fill="none">
                      <path d="M0,0h24v24h-24Z"></path>
                      <path stroke="#323232" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M8,10l4,4l4,-4"></path>
                    </g>
                  </svg>
                </div>
              </div>


            </div>



            <div>
            <h3 class="text-2xl font-semibold leading-5 flex flex-row items-center mt-10">
              10. developer information
            </h3>
            <div class="flex flex-row">
              <div v-if="true" class="w-6/12">
                <label class="block text-sm font-medium leading-5 text-gray-700 mt-5">Match _id: Developer info, niet aanpassen aub
                </label>
                <div class="mt-2 flex rounded-md shadow-sm relative flex-row items-center">
                  <input class="form-input flex-1 block rounded-none rounded-r-md transition duration-150 ease-in-out text-sm sm:leading-5 border-gray-300" placeholder="Match id" v-model="object.matchId" @change="change()" />
                </div>
              </div>



            </div>
          </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import zipCodes from "@/assets/zipCodes";
import env from "@/../env.js";
import Cookies from "@/helpers/cookies";
import { v4 as uuidv4 } from "uuid";
import API from '@/helpers/api'

const axios = require("axios");
export default {
  name: "home",
  data: function() {
    return {
      zipCodes,
      object: {},
      objectMatch: {},
      changesButNotSaved: false,
      env,
      settings: {},
      changed: false,
      uploaded: false,
      cs: false,
      festivals: [],
      festival: false,
      popup: false,
      matchCreated: false
    };
  },
  watch: {
    'object.festival': function(){
      this.updateFestival()
    },
    '$route.params.id': async function(){
      await this.load();
    },
    object: function(now, prev) {
      // We want to exclude the load of a page.
      if (Object.keys(prev).length > 0) {
        this.changesButNotSaved = true;
      }
    }
  },
  beforeRouteLeave(to, from, next) {
    if (this.changesButNotSaved) {
      const answer = window.confirm(
        "Je hebt je veranderingen nog niet opgeslagen!"
      );
      if (answer) {
        next();
      } else {
        next(false);
      }
    } else {
      next();
    }
  },
  methods: {
    updateFestival: function(){
      if(this.object.festival){
        for (var i = 0; i < this.festivals.length; i++) {
          if(this.festivals[i]._id == this.object.festival){
            this.festival = this.festivals[i];
          }
        }
      }
    },
    add: function() {
      this.object = {};
      this.popup = true;
    },
    load: async function() {
      //const _self = this;
      //console.log(_self.$route.query.matchId);
      const query = this.$route.query.matchId ? "?matchId=" + this.$route.query.matchId : "";
      const { data } = await axios.get(`${env.apiURL}/plekjes/${this.$route.params.id}${query}`,
        {
          headers: { Authorization: "Bearer " + Cookies.get("session") }
        }
      );

      // set the object
      this.object = data;
      this.object.lid = this.object.lid.toLowerCase();
      // does this plekje have a match?

      /*
      this.object.match =
        this.object.match &&
        this.object.match.length > 0 &&
        this.object.match.filter(a => a.matchAllowed)[0];
        */

      // is this plekje member?
      if (!this.object.lid || this.object.lid === "") {
        this.object.lid = "Geen";
      }

      /*
      for (let i = 0; i < this.object.platforms.length; i++) {
        if (this.object.platforms[i] === "circuitsortie.be") {
          this.cs = true;
        } else if (this.object.platforms[i] === "genereuzefestivals.be") {
          this.festivals = true;
        }
      }
      */
    },
    approvePlekje: function(plekje) {
      const _self = this;
      plekje.checked = true;
      plekje.active = true;
      axios
        .put(env.apiURL + "/plekjes/" + plekje._id, plekje, {
          headers: {
            Authorization: "Bearer " + Cookies.get("session")
          }
        })
        .then(function() {
          _self.changesButNotSaved = false;
          alert("Saved");
        });
    },
    undeletePlekje: function(plekje) {
      const _self = this;
      plekje.removed = false;
      axios
        .put(env.apiURL + "/plekjes/" + plekje._id, plekje, {
          headers: {
            Authorization: "Bearer " + Cookies.get("session")
          }
        })
        .then(function() {
          _self.changesButNotSaved = false;
          alert("Saved");
        });
    },
    deletePlekje: async function(plekjeId) {
      if(confirm('Ben je zeker dat je dit plekje wilt verwijderen?')){
        await API.delete("/plekjes/" + plekjeId);
        this.$router.push({path: '/plekjes'})
      }
    },
    copyPlekje: async function() {
      const plekje = await API.post("/plekjes/copy/", this.object);
      alert('Plekje is gekopieerd. We redirecten je nu naar dit nieuwe plekje.');
      this.$router.push({ path: '/plekjes/'+plekje._id})
    },
    save: function() {
      const _self = this;
      axios
        .put(env.apiURL + "/plekjes/" + this.object._id, this.object, {
          headers: {
            Authorization: "Bearer " + Cookies.get("session")
          }
        })
        .then(function() {
          _self.changed = false;
          _self.changesButNotSaved = false;
          alert("Saved");
        });
    },
    open: function() {
      const url = this.settings.dashboardURL + "/plekjes/" + this.object._id;
      document.location = url;
    },
    openPopup: function() {
      this.popup = true;
    },
    closePopup: function() {
      this.popup = false;
    },
    remove: function() {
      if (confirm("Are you sure? This action cannot be undone!!")) {
        axios
          .delete(env.apiURL + "/plekjes/" + this.object._id, {
            headers: {
              Authorization: "Bearer " + Cookies.get("session")
            }
          })
          .then(function() {
            document.location = "/";
          });
      }
    },
    sendFile: function(formData, type) {
      const _self = this;
      return new Promise(function(resolve) {
        fetch(env.apiURL + "/files/upload/" + type, {
          method: "POST",
          body: formData
        })
          .then(res => res.json())
          .then(res => {
            console.log("Done uploading", res);
            resolve(res.file);
            if (type === "plekje") {
              _self.object.image = res.file;
            } else {
              _self.objectMatch.image = res.file;
              _self.$refs.matchImage.src = res.file;
            }
          })
          .catch(e => {
            console.error(JSON.stringify(e.message));
            resolve(false);
          });
      });
    },
    uploadFiles: async function(type) {
      let droppedFiles = event.target.files[0];
      console.log(droppedFiles);
      if (!droppedFiles) return;
      if (droppedFiles.length < 1) return;
      let formData = new FormData();
      formData.append("file", droppedFiles);
      await this.sendFile(formData, type);
      this.uploaded = true;
      this.change();
    },
    formatBirthYear: function(date) {
      const newDate = new Date(date);
      return newDate.getUTCFullYear();
    },
    goback: function() {
      const _self = this;
      console.log(_self.changed);
      if (!_self.changed) {
        this.$router.go(-1);
      } else {
        const c = confirm(
          "Weet u zeker dat u de pagina wilt verlaten? Uw wijzigingen zijn nog niet opgeslagen"
        );
        if (c === true) {
          this.$router.go(-1);
        }
      }
    },
    change: function() {
      const _self = this;
      _self.changed = true;
    },
    addToPlatforms: function(platform, add) {
      if (!this.object.platforms) this.object.platforms = [];
      if (add) {
        this.object.platforms.push(platform);
      } else {
        for (let i = 0; i < this.object.platforms.length; i++) {
          if (this.object.platforms[i] === platform) {
            this.object.platforms.splice(i, 1);
          }
        }
      }
      this.change();
    },
    createMatch() {
      const _self = this;
      this.objectMatch.id = uuidv4();
      if (this.object.id) {
        this.objectMatch.plekjeId = this.object.id;
      } else {
        this.objectMatch.plekjeId = this.object._id;
      }
      this.objectMatch.matchAllowed = true;
      this.objectMatch.checked = true;
      this.objectMatch.active = true;
      axios
        .post(env.apiURL + "/matches", this.objectMatch, {
          headers: {
            Authorization: "Bearer " + Cookies.get("session")
          }
        })
        .then(function(res) {
          _self.closePopup();
          _self.object.hasMatch = true;
          _self.object.active = false;
          _self.object.hasReply = true;
          _self.object.matchId = res.data._id;
          console.log(_self.object);
          axios
            .put(env.apiURL + "/plekjes/" + _self.object._id, _self.object, {
              headers: {
                Authorization: "Bearer " + Cookies.get("session")
              }
            })
            .then(function(res) {
              console.log(res.data);
              _self.load();
            });
        });
    }
  },
  async mounted() {
    await this.load();


    const festivals = await API.get("/festivals");
    this.festivals = festivals.data;
    this.updateFestival();


    this.settings = await API.get("/settings");


  }
};
</script>

<style>
.form-input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: #fff;
  border-width: 1px;
  border-radius: 0.375rem;
  padding: 0.5rem 0.75rem;
  line-height: 1.5;
}

.form-input:focus {
  outline: none;
  box-shadow: 0 0 0 3px rgba(164, 202, 254, 0.45);
  border-color: #a4cafe;
}
</style>
